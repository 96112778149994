<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false"
    ></loading>

    <TermsAcceptance              
      v-if="userProfile!= null && (userProfile.flag_terminos == null || userProfile.flag_terminos.id == 2) 
        && userProfile.type == 'client'"
      :userProfile="userProfile"/>

    <CModal title="Perfil de paseador" :show.sync="modalProfile" size="xl">
      <b-row>
        <b-col lg="4" offset-lg="4" style="text-align:center;">
          <b-form-group>
            <img
              :src="walkerPicture ? walkerPicture : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'"
              alt
              class="img-thumbnail rounded mx-auto d-block"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <strong>Nombre:</strong>
          <br />
          {{walkerName}}
        </b-col>
        <b-col md="6">
          <strong>Cédula:</strong>
          <br />
          {{walkerDni}}
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col md="6">
          <strong>Ocupación:</strong>
          <br />
          {{walkerOccupation}}
        </b-col>
        <b-col md="6" v-if="walkerAntecedents != null">
          <br />
          <a :href="walkerAntecedents" target="_blank">Ver antecedentes</a>
        </b-col>
        <b-col md="6">                             
          <a :href="`tel:${walkerPhone}`">
            <b-button size="md">
              <CIcon size="lg" :name="'cilPhone'" /> Llamar
            </b-button>
          </a>
        </b-col>
      </b-row>
      <template v-slot:footer-wrapper>
        <div></div>
      </template>
    </CModal>
    <div v-if="!loading">
      <b-row>
        <!-- Map -->     
        <b-col lg="7">
          <Counter
            v-if="counterFlag"
            :year="endDate.year"
            :month="endDate.month"
            :date="endDate.date"
            :hour="endDate.hour"
            :minute="endDate.minute"
            :second="endDate.second"
            :millisecond="endDate.millisecond"
            @refresh="refresh=$event"/>
            <!-- counterFlag: {{counterFlag}}, Refresh: {{refresh}} -->
          <section>
            <gmap-map 
              v-bind:center="center" 
              v-bind:zoom="15" 
              class="map-tracking-props"              
              >
              <gmap-polyline v-bind:path.sync="path" v-bind:options="{ strokeColor:'#f86c6b'}"></gmap-polyline>
              <gmap-marker
                v-bind:key="index"
                v-for="(m, index) in homeLocation"
                :position="m.position"
                :icon="dogHomeIcon"
                @click="center=m.position"
                :draggable="true"
              ></gmap-marker>
              <gmap-marker
                v-for="(m, index2) in petLocation"
                v-bind:key="index2"
                :position="m.position"
                :icon="dogPositionIcon"
                @click="center=m.position"
                :animation="1"
                :draggable="true"
              ></gmap-marker>
            </gmap-map>
          </section>
        </b-col>

        <!-- Personal information -->
        <b-col lg="5">
          <section>
            <b-row>
              <b-col lg="12" style="text-align:center;">
                <h4>Datos del paseo</h4>
              </b-col>

              <!-- <b-row> -->
              <b-col lg="12" offset-lg="12">
                <b-button class="mr-1 s-bg s-bg-text" style="width:100%;" @click="getService()">
                  <strong>Actualizar ubicación</strong>
                </b-button>
                <p></p>
              </b-col>
              <!-- </b-row> -->

              <b-col lg="12">
                <CWidgetIcon :header="walkerName" text="Paseador" color="gradient-info">
                  <CIcon name="cil-user" width="24" />
                  <template #footer>
                    <CCardFooter class="px-3 py-2">
                      <CLink
                        class="font-weight-bold font-xs text-muted d-flex justify-content-between"
                        @click="openProfile()"
                        target="_blank"
                      >
                        Ver perfil
                        <CIcon name="cil-arrow-right" width="16" />
                      </CLink>
                    </CCardFooter>
                  </template>
                </CWidgetIcon>
              </b-col>
              <b-col lg="12">
                <CWidgetIcon :header="petName" text="Mascota" color="gradient-info">
                  <CIcon name="cil-animal" width="24" />
                </CWidgetIcon>
              </b-col>

              <b-col lg="12">
                <CWidgetIcon
                  :header="totalDistance"
                  text="Distancia (km)"
                  color="gradient-info"
                  class="text-info"
                >
                  <CIcon name="cil-graph" width="24" />
                </CWidgetIcon>
              </b-col>

              <b-col lg="12">
                <CWidgetIcon
                  :header="serviceStateName"
                  text="Estado"
                  color="gradient-info"
                  :class="textClassState"
                >
                  <CIcon name="cil-bell" width="24" />
                </CWidgetIcon>
              </b-col>

              <b-col lg="12">
                <CWidgetIcon
                  :header="ServiceStartDateTime | formatDateTime2"
                  text="Fecha inicio"
                  color="gradient-success"
                >
                  <CIcon name="cil-alarm" width="24" />
                </CWidgetIcon>
              </b-col>

              <b-col lg="12">
                <CWidgetIcon
                  :header="ServiceEndDateTime | formatDateTime2"
                  text="Fecha fin"
                  color="gradient-danger"
                >
                  <CIcon name="cil-alarm" width="24" />
                </CWidgetIcon>
              </b-col>
            </b-row>
          </section>
        </b-col>
      </b-row>

      <!-- Evidence -->
      <b-row v-if="walkerComments != ''">
        <b-col lg="6">
          <h4>Comentarios del paseo</h4>
          {{walkerComments}}
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col lg="12">
          <h4 v-if="pic1 != '' || pic2 != ''">Fotos</h4>
        </b-col>
        <b-col lg="6" v-if="pic1 != ''">
          <img 
            :src="pic1" 
            class="c-sidebar-brand-minimized img-props"/>             
        </b-col>
        <b-col lg="6" v-if="pic2 != null">
          <img 
            :src="pic2" 
            class="c-sidebar-brand-minimized img-props"/>   
        </b-col>

      </b-row>
        {{callGetService}}
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Loading from "vue-loading-overlay";
import Counter from "@/components/Counter";
import TermsAcceptance from "../modals/TermsAcceptance"

export default {
  name: "SeguimientoGPS",
  components: { Loading, Counter, TermsAcceptance },
  data() {
    return {
      userProfile: null,
      center: { lat: 0, lng: 0 },
      homeLocation: null,
      currentPlace: null,
      dogPositionIcon: require("@/assets/images/huella24.png"),
      dogHomeIcon: require("@/assets/images/casa-de-perro32.png"),
      idObjeto: null,
      petName: null,
      walkerName: null,
      walkerPicture: null,
      walkerAntecedents: null,
      walkerDni: null,
      walkerOccupation: null,
      walkerPhone: null,
      serviceStateName: null,
      serviceStateId: null,
      ServiceStartDateTime: null,
      ServiceEndDateTime: null,
      coordinates: null,
      dogPosition: null,
      totalDistance: null,
      loading: true,
      fecha_i: null,
      map: null,
      searchAddressInput: "",
      modalProfile: false,
      //evidence
      pic1: '',
      pic2: '',
      walkerComments: '',
      endDate: {
        year: null,
        month: null,
        date: null,
        minute: null,
        second: null,
        millisecond: null,
      },
      counterFlag: false,
      refresh: false,
      counterRefreshs: 0
    };
  },
  computed: {
    path: {
      get() {
        return this.coordinates;
      },
      set(newValue) {
        return newValue;
      }
    },
    petLocation: {
      get() {
        return this.dogPosition;
      },
      set(newValue) {
        return newValue;
      }
    },
    textClassState: {
      get() {
        if (this.serviceStateId == 2) {
          return "text-success";
        } else if (this.serviceStateId == 34) {
          return "text-danger";
        } else {
          return "text-info";
        }
      },
      set(newValue) {
        return newValue;
      }
    },
    callGetService(){
      if(this.refresh ){                    
        if(this.counterRefreshs <= 10) {          
          this.getEndDate();
          this.counterFlag = false
          this.getService()
          this.counterRefreshs++
        } else {
          this.counterFlag = false
        }
      }
    }
  },
  created() { 
    this.getEndDate();
   // http://localhost:8080/services/gps?idObjeto=21926&fecha_i=2022-01-25T11%3A06%3A09
    if (this.$route.query.idObjeto != null) {
      // console.log(this.$route.query)
      this.idObjeto = this.$route.query.idObjeto;
      this.fecha_i = this.$route.query.fecha;
      // console.log("idObjeto:", this.idObjeto);
      // console.log("fechaInicio:", this.fecha_i);

      this.getService();
    }
    this.userProfile = JSON.parse(this.$store.getters.userProfile);              
    if (this.userProfile == null) {
      this.$router.push({
        name: "Login",
        query: {
          redirect: "serviceGPS",
          idObjeto: this.idObjeto,
          fecha_i: this.fecha_i
        }
      });
    }

    // if(userProfile.type == 'client'){
    //   console.log('you are a client')
    // }
  },
  methods: {  
    getEndDate () {
      let end = moment(new Date()).add(4, 'minutes')      
      this.endDate = {
        year: end.format('yyyy'),
        month: end.format('M') - 1,
        date: end.format('D'),
        hour: end.format('H'),
        minute: end.format('mm'),
        second: end.format('s'),
        millisecond: end.format('SSS'),
      }      
      this.counterFlag = true
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    getService() {       
      this.loading = true;
      let url =
        "https://us-central1-test-goguau.cloudfunctions.net/gpsGetService/goguau/getService";
      // this.fecha_i =
      //   moment(this.fecha_i).format("YYYY-MM-DDThh:mm:ss") + ".000Z";
      // console.log(this.fecha_i);
      let body = {
        objeto: {
          id: this.idObjeto,
          fecha_i: this.fecha_i
        }
      };
      let bodyS = JSON.stringify(body);
      // console.log("body sent:", bodyS);

      this.$http.post(url, bodyS, { headers: {} }).then(response => {
          // console.log("body:", response.body);
          // console.log(JSON.stringify(response.body.resp))
          this.path = null;
          this.petLocation = null;
          this.setServiceInfo(response.body);
        })
        .catch(e => {
          console.log(e);
        }).finally(()=>{          
          this.loading = false;
          this.counterFlag = this.counterFlag = this.serviceStateId == 2 ?  true : false;
          this.refresh = false;
        })
    },
    setServiceInfo(data) {
      console.log(data)
      if ('pic1' in data)
        this.pic1 = data.pic1
      if ('pic2' in data)
        this.pic2 = data.pic2
      if ('comments' in data)
        this.walkerComments = data.comments
      this.petName = data.petName;
      this.walkerName = data.walkerName;
      this.walkerPicture =
        data.walkerPicture != null
          ? data.walkerPicture
          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";
      this.walkerAntecedents = data.walkerAntecedents;
      this.walkerDni = data.walkerDni;
      this.walkerOccupation = data.walkerOccupation;
      this.walkerPhone = data.walkerPhone;
      this.ServiceStartDateTime = data.fechaInicio;
      // this.ServiceStartDateTime = moment(this.ServiceStartDateTime).subtract(5,'hours').format("YYYY-MM-DDThh:mm:ss")+".000Z"
      this.ServiceEndDateTime = data.fechaFin;
      this.totalDistance = data.totalDistance;
      this.serviceStateId = data.serviceStateId;

      switch (data.serviceStateId) {
        case 2:
          // iniciar paseo
          this.serviceStateName = "En ejecución";
          break;
        case 34:
          // Finalizar paseo
          this.serviceStateName = "Finalizado";
          break;

        default:
          this.serviceStateName = data.serviceStateName;
          break;
      }

      let serviceCoordinates = data.resp;      
      let lastPosition = serviceCoordinates[serviceCoordinates.length - 1];
      this.center = {
        lat: parseFloat(data.petHomePosition.lat),
        lng: parseFloat(data.petHomePosition.lng)
      };

      (
        this.homeLocation = [
          {
            position: {
              lat: parseFloat(data.petHomePosition.lat),
              lng: parseFloat(data.petHomePosition.lng)
            }
          }
        ]
      ),
      (
        this.dogPosition = [
          {
            position: {
              lat: parseFloat(lastPosition.lat),
              lng: parseFloat(lastPosition.lng)
            }
          }
        ]
      ),
      this.coordinates = serviceCoordinates;
      let lastCoordinate = this.coordinates.length - 1

      this.coordinates[0].lat = Number(this.coordinates[0].lat)
      this.coordinates[0].lng = Number(this.coordinates[0].lng)
      
      this.coordinates[lastCoordinate].lat = Number(this.coordinates[lastCoordinate].lat)
      this.coordinates[lastCoordinate].lng = Number(this.coordinates[lastCoordinate].lng)
      // console.log('coordinates: ', this.coordinates);
      // console.log('dogPosition: ', this.dogPosition)
      this.loading = false;
    },
    openProfile() {
      this.modalProfile = true;
    }
  }
};
</script>

<style scoped>
.profile-user-image {
  border-radius: 50%;
  border: 2px solid white;
  width: 7rem;
  height: 7rem;
  /* width: 10vw;
    height: 10vw; */
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  background-color: white;
}
.img-props {
  height: 20rem;
}

</style>